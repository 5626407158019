.headerImage {
  width: 100vw;
  height: 100vh;
  background: url('../../assets/header-min.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  background-color: #6f6f6f;
}

.headerTitle {
  width: 100%;
  top: 43%;
  color: #e0e0e0;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  position: relative;
  height:fit-content;
}

#iamList {
  /* animation-name: textFade;
  animation-duration: 3s;
  animation-iteration-count: infinite; */
}

@keyframes textFade {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

@media only screen and (max-width: 1100px){
  .headerTitle h1 {
    font-size: 1.5em;
  }
  
  .headerTitle h2 {
    font-size: 1em;
  }
}