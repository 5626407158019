
.skillsSection {
  background-color: white;
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
}

.sectionTitle {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillsOuterWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.skillsInnerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 600px;
  justify-content: center;
}

.skillItem {
  margin: 30px 20px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}