.aboutMeSection {
  padding: 20px;
  font-size: 1.2em;
  background-color: white;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutMeDesc {
  max-width: 500px;
}

.aboutMeDesc > p > a {
    color: black;
    text-decoration: underline;
}

.About {
    text-align: left;
}

/* social links */
.social-links {
    max-width: 500px;
}

ul {
    padding: 0px;
}

ul.social-buttons {
  text-align: center;
}

ul.social-buttons li {
  display: inline-block;
  margin: 0 10px;
}

ul.social-buttons li a {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50px;
  text-decoration: none;
  font-size: 30px;
  color: white;
  fill: white;
  line-height: 52px;
}

ul.social-buttons li a.email-link {
  background-color: #da3e2a;
}

ul.social-buttons li a.github-link {
  background-color: #24292e;
}

ul.social-buttons li a.linkedin-link {
  background-color: #0e76a8;
  
}

/* buttons animation */
.social-buttons li a {
  transition: transform 0.2s ease-in-out 0
}

.social-buttons li:hover a {
  transform: rotateZ(27deg) scale(1.1);
}
