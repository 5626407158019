.portfolioItemSmallText {
  font-size: 10px;
}

.portfolioSection {
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  background-color: #f2f2f2;
}

.portfolioItemContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


/* My page no longer uses this animation */
/* but im leaving it here incase I ever want to us it agian */
/* I won't have to type 20 lines of animation */
@keyframes linkColorFlash {
  0% {
    color: black;
  }
  20% {
    color: red;
  }
  40% {
    color: orange;
  }
  60% {
    color: green;
  }
  80% {
    color: blue;
  }
  100% {
    color: black;
  }
}