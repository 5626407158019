.PortfolioItemDetails {
    padding-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.portfolioDetailWrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
}

.detailImage > img {
    max-width: 100%;
}

.detailImage {
    margin-top: 20px;
    max-width: 50%;
}

.detailInfo {
    font-size: 18px;
    margin: 20px 0;
    max-width: 45%;
    text-align: left;
}

.detailInfo > h1 {
    margin-top: 0;
}

.button {
    min-height: 45px;
    padding: 10px;
    text-align: center;
    border-radius: 15px;
    margin-top:10px;
    color: rgb(41, 41, 41);
    border: 2px solid rgb(41, 41, 41);
    text-decoration: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hoverfill {
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}
.hoverfill:hover {
    background-color: rgb(47, 47, 47);
    color: white;
}

.detailButtons {
    display: flex;
    flex-direction: column;
    width: 50%;
}

@media only screen and (max-width: 900px){
  .portfolioDetailWrapper {
      flex-direction: column;
      align-items: center;
  }

  .detailImage {
      max-width: 90%;
  }

  .detailInfo {
      max-width: 90%;
  }
}

.iconImage {
    max-width: 45px;
    max-height: 45px;
    margin-left: 10px;
}