.navbar {
  background-color: #fff;
  min-height: 20px;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 4;
}

.navbarOpaque {
  animation-name: navBarAppear;
  animation-duration: 0.3s;
  opacity: 1;
}

.navbarTranslucent {
  animation-name: navBarHide;
  animation-duration: 0.3s;
  opacity: 0;
}

.navbarStart {
  animation-name: navBarHide;
  animation-duration: 0s;
  opacity: 0;
}

@keyframes navBarAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes navBarHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.navTextDark, .navTextDark > a {
    color:rgb(22, 22, 22);
}

.navTextLight, .navTextLight > a {
    color: white;
}

.navTitle {
  font-weight: bold;
  z-index: 3;
}

.navItems {
  display: flex;
  gap: 30px;
}

.navButton {
  font-weight: bold;
}

.navbarContainer {
  position: fixed;
  z-index: 5;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px 0 30px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: space-between;
}
.navItems > a, .navTitle {
  text-decoration: none;
}

