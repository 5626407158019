.PortfolioItem img {
  width: 250px;
  height: 200px;
}

.hoverDiv {
  width: 190px;
  height: 140px;
  overflow: hidden;
  display: inline;
  position: absolute;
  background-color: white;
  z-index: 2;
  margin-left: -240px;
  margin-top: 10px;
  text-align: center;
  transform: scale(0);
  padding: 20px;
}

.PortfolioItem {
  width: 250px;
  height: 200px;
  margin: 15px;
}

.hoverDivExpand {
  transform: scale(1);
  transition-duration: 0.5s;
}

.hoverDivRetract {
  transform: scale(0);
  transition-duration: 0.5s;
}

.PortfolioItem > a {
    color: black;
}